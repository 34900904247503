const idTranslation = {
  translation: {
    iosAlert:
      "Pengguna IOS dan Mac harus masuk ke bagian 'Pengaturan' di browser mereka dan ketuk opsi 'Tambahkan ke Layar Utama' untuk menginstal perangkat lunak.",
    installDescription:
      "Instal aplikasi di perangkat Anda untuk mengaksesnya dengan mudah kapan saja. Tidak perlu toko aplikasi. Tidak ada unduhan. Tidak ada kerumitan.",
    homePage: {
      muscleAgeTitle: "Temukan usia otot Anda",
      exercise:
        "Ayo coba tantangan 1 Menit Berdiri dari Kursi untuk melihat berapa usia ototmu sekarang! 💪Siap untuk memulai? 😊",
      atrophy:
        "Setelah usia 40 tahun, kamu dapat kehilangan massa otot hingga 15% setiap 10 tahun!<superScript>[1]</superScript>.",
      personalInformation: "Informasi Pribadi",
      selectYourAge: "Masukkan Usia Anda",
      selectGender: "Pilih Jenis Kelamin",
      male: "Laki-Laki",
      female: "Perempuan",
      firstName: "Nama depan",
      lastName: "Nama belakang",
      emailAddress: "Alamat email",
      mobilePhone: "Nomor telepon",
      muscleAge: "Lihat Hasil Pengukuran",
      limitation:
        "Alat pengukuran ini hanya berlaku untuk usia 40 tahun ke atas",
      muscleAgeAccessCamera: "Mengakses Kamera...",
      startYourMuscleAge:
        "Penting untuk memantau dan menjaga kekuatan otot Anda untuk mencegah jatuh dan terus menjalani hidup yang sehat dan aktif. Itulah mengapa kami mengembangkan Tantangan Berdiri dari Kursi - berdasarkan tes Berdiri dari Kursi 5 kali, untuk membantu menunjukkan kekuatan dan performa fisik anggota tubuh bagian bawah Anda. Sesi Digital Langsung yang inovatif dirancang untuk menggunakan kamera komputer Anda untuk membantu Anda melalui Tantangan ini dan menentukan usia otot Anda.",
    },

    errors: {
      requiredField: "$t(homePage.{{fieldName}}) diperlukan",
      invalidField: "Masukkan $t(homePage.{{fieldName}}) yang valid",
    },

    cameraFallback: {
      camera: "Tidak Ingin Menyalakan Kamera? Tidak Masalah!",
      encouraging:
        "Hitung waktu Anda melakukan Tantangan Berdiri dari Kursi menggunakan stopwatch dan masukkan hasil Anda dalam formulir untuk melihat berapa usia otot Anda!",
      getStartExplain:
        "Setelah usia 40 tahun, Anda dapat kehilangan hingga 15% massa otot setiap 10 tahun. Luangkan 3 menit dengan tes Berdiri dari Kursi kami untuk mengetahui usia otot Anda.",
      motivating: "Bersiap Untuk Memulai Tantangan",
      completeTestRep: "Complete a Test Repetition",
      explanatory:
        "Untuk menyelesaikan Tantangan Berdiri dari Kursi, Anda perlu menemukan kursi yang dapat Anda gunakan untuk duduk dan berdiri.",
      stepByStep:
        "Untuk melakukan gerakan Berdiri dari Kursi secara berulang, posisikan tangan dilipat di dada, Anda perlu duduk di kursi dan berdiri secepat mungkin untuk menyelesaikan gerakan 5x secara berulang.",
      instructive:
        "Ketika Anda sudah siap, ukur seberapa cepat Anda dapat menyelesaikan 5 ulangan Berdiri dari Kursi. Setelah selesai, masukkan total waktu Anda di bawah ini untuk mengetahui usia otot Anda!",
      inputTestTime: "Masukkan Hasil Waktu Anda (dalam detik)",
      yourTime: "Waktu Tes Anda",
      calculateMuscle: "Hitung Usia Otot Saya!",
      startChallenge: "Mulai Tantangannya",
    },

    userPreparation: {
      chair: "Tempatkan kursi 1-2 meter di depan kamera",
      wait: "Harap tunggu sistem AI sedang dimuat",
      notVisibleKnees:
        "Pastikan <bold>lutut & bahu </bold> Anda terlihat!",
      nextStep: "Lanjutkan ke langkah berikutnya",
      description: "Tonton video instruksional Tantangan Berdiri dari Kursi",
      startTheChairStandTest: "Mulai Tantangan Berdiri dari Kursi",
    },

    SquatTest: {
      1: "Bagus! 4x lagi!",
      2: "",
      3: "Sempurna! Anda sudah setengah jalan!",
      attach: "2x lagi!",
      4: "Yang terakhir!",
      default: "Selesaikan 5 pengulangan Berdiri dari Kursi untuk melihat usia otot Anda!",
    },

    testCompletionMessage: {
      congratulation: "Anda telah menyelesaikan Tantangan Berdiri dari Kursi!",
      processing: "Sekarang menghitung usia otot Anda...",
    },

    results: {
      output: "Usia Otot Anda: ",
      year: " th",
      shareResults: "Bagikan Hasil Anda",
      invalidLinkOrUnsupportedProcess:
        "Tautan ini rusak atau prosesnya salah, silakan coba lagi!",

      utterGoodTipTop: "Bagus sekali! Usia otot Anda lebih muda daripada usia Anda.",
      utterGood: "Usia otot Anda lebih muda daripada usia Anda.",
      utterBad:
        "Wah! Usia otot Anda lebih tua daripada usia Anda saat ini. Yuk mulai untuk fokus membangun massa dan kekuatan otot Anda.",
      utterOnPar:
        "Anda hanya selangkah lagi dari puncak kekuatan Anda!",
      footNoteFaster75:
        "Hasil Anda lebih cepat daripada 75% rekan-rekan Anda dalam rentang usia {{actualAge}} tahun.",
      footNoteSlower75:
        "Hasil Anda lebih lambat dari 75% kelompok umur berusia {{actualAge}} tahun.",
      percentileBelow25:
        "Hasil Anda berada di bawah persentil ke-25 untuk kategori usia 40 hingga 44 tahun.",
      percentileAbove25:
        "Hasil Anda sama dengan atau lebih tinggi dari persentil ke-25 untuk kategori usia {{outputAge}}.",
      percentileBelow75:
  "Hasilmu setara atau di bawah persentil ke-75 untuk kategori usia {{outputAge}} tahun.",
      footNoteOnPar: "Bagus! Usia otot Anda sesuai dengan usia Anda saat ini.",
      footNoteFaster:
        "Kekuatan Anda adalah rata-rata untuk usia {{outputAge}} ({{outputAgeRange}}) dan juga lebih kuat dari rata-rata untuk usia Anda ({{actualAge}})!",
      footNoteOnPeriod:
        "Kekuatan Anda berada pada rata-rata kekuatan untuk usia {{outputAge}} ({{outputAgeRange}}) Anda juga lebih lemah dari rata-rata untuk usia Anda ({{actualAge}})!",
      footNoteSlower:
        "Kekuatan Anda berada pada rata-rata kekuatan untuk usia {{outputAge}} ({{outputAgeRange}}) Anda juga lebih lemah dari rata-rata untuk usia Anda ({{actualAge}})!",
      footNoteSlower80:
        "Kekuatan Anda lebih lemah dari rata-rata kekuatan untuk usia Anda +80!",
    },

    manualTest: {
      manualbutton:
        "Tidak ada Kamera? Tidak masalah! Anda dapat menghitung waktu sendiri saat melakukan Tantangan Berdiri dari Kursi dan masukkan waktu Anda ke kalkulator secara manual.<bold> Ikuti tantangan tanpa kamera di sini</bold>",
      EnterDetails: "Masukkan detail Anda ke kalkulator usia otot di bawah ini.",
      Step3: "Langkah 3",
      age: "Usia",
      gender: "Jenis Kelamin",
      timeInSeconds: "Waktu dalam Detik*",
      submit: "KIRIM",
      sitToStand: "Waktu Berdiri dari Duduk (contoh: 7,5 detik)",
      Title: "Ikuti tes kami untuk mengetahui usia otot Anda sekarang",
      forYourOwnSafety: "Untuk keamanan Anda sendiri",
      forYourOwnSafetyList: {
        1: "Berhati-hatilah agar tidak tegang atau cedera.",
        2: "Hentikan tantangan segera jika lutut Anda mulai sakit.",
        3: "Jangan bersandar ke belakang untuk mendapatkan momentum atau Anda bisa terjatuh.",
        4: "Lakukan tes saat ada teman atau anggota keluarga di sekitar untuk mendukung Anda.",
        5: "Jangan mencoba tes jika Anda baru saja terjatuh atau cedera.",
      },
    },

    calculation:
      "Hasil dari kalkulator usia otot berdasarkan pada referensi tes berdiri dari kursi 5 kali yang dapat menjadi indikasi kekuatan dan kinerja fisik otot di tungkai bawah kaki.",
    reference:
      "*Baier S et al. JPEN 2009; 33:71-82. Berdasarkan pengukuran massa tubuh tanpa lemak (LBM).",
    didYouKnow: "TAHUKAH KAMU?",
    understand: "Saya mengerti",
    safety_notes: "Catatan Keamanan",
  },
};

export default idTranslation;
